import { createApp, provide, h } from 'vue'
//import vuetify from '@/plugins/vuetify' 
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
  })
import router from './router'
// import store from './store'
import App from './App.vue'

// Apollo
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client/core'

const httpLink = new HttpLink({ uri: 'https://api.zwinkels.fr/graphql/' });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: 'Bearer 1234567890abcdefghijklmopqrstuvwxyz' //localStorage.getItem('token') || null,
    }
  }));

  return forward(operation);
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },

  render: () => h(App),
})

app.use(router)
app.use(vuetify)
app.mount('#app')