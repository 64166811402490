import gql from 'graphql-tag';

export const SEND_MESSAGE = gql`
mutation sendMessage ($service: String!, $name: String!, $email: String!, $message: String!){
  sendMessage (input: { service: $service, name: $name, email: $email, message: $message}) { 
    service 
  }
}`;

export const GET_ATHLETE = gql`
query stravaathlete ($sourceId: ID!){
  stravaathlete (sourceId: $sourceId) { 
    clubs, stats, koms
  }
}`;

