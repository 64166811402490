<template>
    <v-footer>
        <v-container>
            <v-row>
                <v-col cols="12" sm="3">
                    <h3><a href="https://strava.com/athletes/5690785" class="strava-badge- strava-badge-follow" target="_blank"><img src="//badges.strava.com/echelon-sprite-24.png" alt="Strava" /></a> 
                        stats</h3>
                    <span if="totalrides">total rides {{ totalrides }}</span> <br />
                    <span if="longestride">longest ride {{ longestride }}km.</span> <br />
                    <span if="biggestclimb">biggest climb {{ biggestclimb }}m.</span> <br />
                </v-col>
            
                <v-col cols="12" sm="3">
                    <h3>projects</h3>
                    <a href="https://whiskey-map.com" target="_blank">whiskey-map.com</a> <v-spacer></v-spacer>
                    <router-link to="/api">api's</router-link>
                </v-col>
                <v-col cols="12" sm="3">
			<h3>contact</h3>
			<a href="https://www.linkedin.com/in/timzwinkels/" target="_blank"><img src="../assets/LI-In-Bug.png" style="width:20px;position: relative; top: 2px;" />LinkedIn</a><br />
			<a href="https://stackoverflow.com/users/7563428/tim-zwinkels"><img src="https://stackoverflow.com/users/flair/7563428.png" width="208" height="58" alt="profile for Tim Zwinkels at Stack Overflow, Q&amp;A for professional and enthusiast programmers" title="profile for Tim Zwinkels at Stack Overflow, Q&amp;A for professional and enthusiast programmers"></a>
		</v-col>
            </v-row>
        </v-container>
    </v-footer>
    
</template>

<script>
    import { watch, ref } from 'vue'
    import { useQuery } from '@vue/apollo-composable'
    import { GET_ATHLETE } from '@/app/api/dutchcreation'

    export default {
      name: 'FooterComponent',
      setup() {
        const panel = []
        const longestride = ref();
        const biggestclimb = ref();
        const totalrides = ref();

        const { refetch, result: queryResult, onResult: onQueryResult, loading, error } = useQuery(GET_ATHLETE, 
            {  sourceId: 5690785  }, 
            { fetchPolicy: 'cache-and-network' })
        watch(queryResult, res => {
            console.log(res);
        }, { immediate: true });
        onQueryResult(response => {
            if (response.data) {
                longestride.value = JSON.parse(response.data.stravaathlete.stats).biggest_ride_distance / 1000;
                biggestclimb.value = JSON.parse(response.data.stravaathlete.stats).biggest_climb_elevation_gain ;
                totalrides.value = JSON.parse(response.data.stravaathlete.stats).all_ride_totals.count;
            }
        })

        return {
            panel,
            longestride,
            biggestclimb,
            totalrides,
            loading, 
            error,
            refetch
        }
      }
    }
</script>

<style>
.v-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    font-size: 14px;
}
.strava-badge- { display: inline-block; height: 16px; position: relative; top: 2px; }
  .strava-badge- img { visibility: hidden; height: 16px; }
  .strava-badge-:hover { background-position: 0 -31px; }
  .strava-badge-follow { height: 16px; width: 16px; background: url(//badges.strava.com/echelon-sprite-16.png) no-repeat 0 0; }
</style>
