<template>
  <div>
    <div class="stripe1"></div>
    <div class="stripe2"></div>
    <div class="stripe3"></div>
    <div class="stripe4"></div>
    <div class="stripe5"></div>
  </div>
</template>

<script>
   export default {
      name: 'HeaderComponent'
   }
</script>

<style scoped>
  .stripe1 {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    height: 3px;
    left: 50%;
    background-color: rgb(183, 213, 225);
    z-index: 1;
  }
  .stripe2 {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    height: 3px;
    left: 50%;
    background-color: rgb(238, 182, 196);
  }
  .stripe3 {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    height: 3px;
    left: 50%;
    background-color: rgb(25, 25, 78);
  }
  .stripe4 {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    height: 3px;
    left: 50%;
    background-color: rgb(255, 207, 0);
  }
  .stripe5 {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    height: 3px;
    left: 50%;
    background-color: rgb(65, 182, 141);
  }
</style>
